import axios from "axios";

export const createStripeCustomer = async (name, email) => {
  try {
    const url = "https://api.stripe.com/v1/customers";
    const data = {
      name: name,
      email: email,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    };

    const params = new URLSearchParams(data).toString();

    const response = await axios.post(url, params, config);
    console.log("Stripe customer created:", response.data);
    return response.data.id;
  } catch (error) {
    console.error(
      "Error creating Stripe customer:",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};

export const stripeApi = axios.create({
  baseURL: "https://api.stripe.com/v1",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export const attachPaymentMethod = async (customerId, paymentMethodId) => {
  try {
    const response = await stripeApi.post(
      `/payment_methods/${paymentMethodId}/attach`,
      new URLSearchParams({
        customer: customerId,
      })
    );
    return response.data;
  } catch (error) {
    console.error("Error attaching PaymentMethod:", error);
    throw error;
  }
};

export const createPaymentIntent = async (
  totalAmount,
  customerId,
  paymentMethodId
) => {
  try {
    const response = await stripeApi.post("/payment_intents", {
      amount: Math.round(totalAmount * 100), // Convert to cents
      currency: "usd",
      customer: customerId,
      payment_method: paymentMethodId,
      off_session: true,
      confirm: true,
    });
    return response;
  } catch (error) {
    console.error("Error creating PaymentIntent:", error);
    throw error;
  }
};

export const createPrice = async (stripeProduct, product, intervalCount) => {
  try {
    const response = await stripeApi.post("/prices", {
      product: stripeProduct.id,
      unit_amount: Math.round(parseFloat(product.price) * 100),
      currency: "usd",
      recurring: {
        interval: product.period,
        interval_count: intervalCount,
      },
    });
    return response;
  } catch (error) {
    console.error("Error creating Price:", error);
    throw error;
  }
};

export const createSetupIntent = async (customerId) => {
  try {
    const response = await axios.post(
      "https://api.stripe.com/v1/setup_intents",
      `customer=${customerId}&usage=off_session`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data.client_secret;
  } catch (error) {
    console.error(
      "Error creating SetupIntent:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const createPaymentMethod = async (cardDetails) => {
  try {
    const response = await axios.post(
      "https://api.stripe.com/v1/payment_methods",
      {
        type: "card",
        card: {
          number: cardDetails.number,
          exp_month: cardDetails.exp_month,
          exp_year: cardDetails.exp_year,
          cvc: cardDetails.cvc,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating PaymentMethod:", error);
    throw error;
  }
};

export const updateCustomerDefaultPaymentMethod = async (
  customerId,
  paymentMethodId
) => {
  console.log("The strip data is:::", customerId, paymentMethodId)
  try {
    const response = await axios.post(
      `https://api.stripe.com/v1/customers/${customerId}`,
      `invoice_settings[default_payment_method]=${paymentMethodId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating customer default payment method:", error);
    throw error;
  }
};

export const updateStripeCustomer = async (customerId, name, email) => {
  try {
    const url = `https://api.stripe.com/v1/customers/${customerId}`;
    const data = {
      name: name,
      email: email,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    };

    const params = new URLSearchParams(data).toString();
    const response = await axios.post(url, params, config);

    console.log("Stripe customer updated:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating Stripe customer:", error.response?.data || error.message);
    throw error;
  }
};
