import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import {
  getWorkspaceInfo,
  listCartItems,
  listUserInfos,
  listWorkspaceInfos,
} from "../graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import {
  selectWorkspaceDetails,
  setLoading,
  showToast,
  selectProductCount,
  selectUserDetails,
} from "../redux/userSlice";
import { Badge, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@emotion/react";
import AddIcon from "@mui/icons-material/Add";
import tagManagerEvents from "../screen/GoogleTagManager";
import googleTagTriggers from '../utills/GoogleTagsList/google-tags.json'


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 250,
    color: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      maxHeight: "400px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#232f3e",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.primary.main,
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: theme.palette.primary.dark,
      },
    },
    backgroundColor: "#232f3e",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const style = {
  position: "absolute",
  top: "17%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AppBarComponent({ open, setOpen }) {
  const client = generateClient();
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [userDetail, setUserDetail] = React.useState(null);
  const [workspaceDetail, setWorkspaceDetail] = React.useState(null);
  const [cartQuantity, setCartQuantity] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [allWorkspaces, setAllWorkspaces] = React.useState(null);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const dispatch = useDispatch();
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const [displayPicture, setDisplayPicture] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropdown = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const productCountCart = useSelector(selectProductCount);
  const userDispatchData = useSelector(selectUserDetails);
  const workspaceInfo = useSelector(selectWorkspaceDetails);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    fetchWorkspaceData();
  }, [dispatch, workspaceInfo]);

  React.useEffect(() => {
    fetchData();
  }, [dispatch, userDispatchData]);

  React.useEffect(() => {
    const fetchCartItems = async () => {
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: variables,
      });
      const res = getCartItems.data.listCartItems.items;
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
      let sum = 0;
      if (result) {
        sum = result.reduce((acc, item) => acc + +item.quantity, 0);
      }
      setCartQuantity(sum);
    };
    fetchCartItems();
  }, [dispatch, productCountCart]);

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          user_id: {
            eq: userData?.sub,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });

      const result = getUserData?.data?.listUserInfos?.items;
      const single = result?.filter(
        (user) => user.workspace_id === selectedWorkspaceId
      );
      setDisplayPicture(single);
      setUserDetail(result);
      if (result) {
        if (userData?.role === "super_admin") {
          const getAllWorkspaces = await client.graphql({
            query: listWorkspaceInfos,
          });
          const wsResult = getAllWorkspaces?.data?.listWorkspaceInfos?.items;
          const filtered = wsResult?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          tagManagerEvents(googleTagTriggers.workspaceCount, {
            workspaceCount: filtered?.length
          }
          )
          setAllWorkspaces(filtered);
        } else {
          const workspaceIds = result?.map((id) => id.workspace_id);
          if (workspaceIds) {
            const userPromises = workspaceIds.map(async (wsInfo) => {
              const response = await client.graphql({
                query: getWorkspaceInfo,
                variables: { id: wsInfo },
              });
              return response?.data?.getWorkspaceInfo;
            });
            const WSdata = await Promise.all(userPromises);
            const flatArray = WSdata.flat();
            setAllWorkspaces(flatArray);
          }
        }
        dispatch(setLoading(false));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchWorkspaceData = async () => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },

        },
      };

      const getWorkspaceData = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const result = getWorkspaceData.data.listWorkspaceInfos.items;
      tagManagerEvents(googleTagTriggers.workspaceDetails, {
        workspaceName: result[0]?.name,
        userCount: result[0]?.user_id?.length
      })
      setWorkspaceDetail(result);
      localStorage.setItem("workSpaceName", result[0]?.name)
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  async function handleSignOut() {
    try {
      localStorage.clear();
      await signOut();
      window.location.reload();
      navigate("/login");
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  }

  async function switchWorkspace(id) {
    localStorage.setItem("selectedWorkspaceId", id);
    if (userData?.role !== "super_admin") {
      const single = userDetail?.filter((user) => user.workspace_id === id);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userData,
          role: single?.[0]?.role,
        })
      );
    }

    navigate("/home");
    window.location.reload();
  }
  return (
    <>
      {!isMobile && (
        <AppBar
          position="absolute"
          open={!open}
          style={{ background: "black", height: "5rem", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "2rem",
              marginLeft: "1.5rem",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={workspaceDetail?.[0]?.logo}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "10px",
                    backgroundColor: "#dddddd",
                    border: "2px solid #fff",
                    objectFit: "cover",
                  }}
                  id="demo-customized-button"
                  aria-controls={
                    openDropdown ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openDropdown ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(event) => handleClick(event)}
                  endIcon={<KeyboardArrowDownIcon />}
                />
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={openDropdown}
                  onClose={handleClose}
                >
                  <MenuItem
                    disableRipple
                    onClick={() => navigate("/new_workspace_name")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AddIcon
                          style={{
                            height: "32px",
                            width: "32px",
                            marginRight: "0.7rem",
                            color: "#fff",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Add a workspace
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                  {allWorkspaces?.length > 0 &&
                    allWorkspaces?.map((item) => (
                      <>
                        <MenuItem
                          onClick={() => switchWorkspace(item.id)}
                          disableRipple
                          key={item.id}
                          style={{ width: "22rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.logo}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "10px",
                                  marginRight: "0.7rem",
                                  border: "2px solid #fff",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "16rem",
                                }}
                              >
                                <span>{item.name}</span>
                                <span
                                  style={{
                                    fontSize: "0.8rem",
                                    color: "#e4e4e4",
                                  }}
                                >
                                  {item.url}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {selectedWorkspaceId === item.id ? (
                                <DoneIcon
                                  style={{
                                    margin: "0 auto",
                                    color: "#fff",
                                    fontSize: "1.3rem",
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </MenuItem>
                      </>
                    ))}
                </StyledMenu>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <div>
                  <h3
                    style={{
                      margin: "0px",
                      textAlign: "start",
                      color: "white",
                    }}
                  >
                    {workspaceDetail?.[0]?.name}
                  </h3>
                </div>
                <div style={{ color: "white" }}>
                  {workspaceDetail?.[0]?.url}
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {userData?.role !== "workspace_member" && (
                  <IconButton color="inherit" onClick={() => navigate("/cart")}>
                    <Badge
                      color="error"
                      badgeContent={cartQuantity}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <ShoppingCartIcon
                        style={{ color: "#fff", fontSize: "2rem" }}
                      />
                    </Badge>
                  </IconButton>
                )}
                <IconButton color="inherit" onClick={handleSignOut}>
                  <LogoutIcon style={{ color: "#fff", fontSize: "2rem" }} />
                </IconButton>
                {(userData?.role !== "super_admin" ||
                  (userData?.role === "super_admin" &&
                    displayPicture?.length > 0)) && (
                    <img
                      onClick={() => navigate("/profile-setting")}
                      src={displayPicture?.[0]?.display_picture}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" component="h2">
                Are you sure you want to logout?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Button
                  onClick={handleCloseModal}
                  variant="contained"
                  sx={{ m: 1 }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSignOut}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, mb: 2 }}
                  className="delButton"
                >
                  Logout
                </Button>
              </Typography>
            </Box>
          </Modal>
          {/* </Toolbar> */}
        </AppBar>
      )}
    </>
  );
}
