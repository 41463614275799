import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { signIn } from 'aws-amplify/auth';
import {
  Alert,
  Box,
  Button,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faEnvelope,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

import { setUserInfo, setLoading } from '../redux/userSlice';
import { listUserInfos } from '../graphql/queries';
import Logo from '../Assets/faviconPNG.png';
import LoginBG from '../Assets/login_bg.jpg';

export default function LoginNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = generateClient();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailerror, setEmailError] = useState(false);
  const [emailerrorMessage, setEmailErrorMessage] = useState(false);
  const [passworderror, setPasswordError] = useState(false);
  const [passerrorMessage, setPassErrorMessage] = useState(false);
  const [servererror, setServerError] = useState('');
  const [servererrorMessage, setServerErrorMessage] = useState('');
  const user = localStorage.getItem('user');

  const fetchData = async (user_id, userAttributes) => {
    try {
      const variables = {
        filter: {
          user_id: {
            eq: user_id,
          },
        },
        limit: 1000,
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });
      const result = getUserData.data.listUserInfos.items[0];


      if (result) {
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...userAttributes,
            role: 'workspace_member',
          })
        );
        navigate('/choose-organization');
      }
    } catch (error) {
      console.error('Error creating todo:', error);
    }
  };

  const currentAuthenticatedUser = async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken.payload['cognito:groups'];
      return groups;
    } catch (err) {
      console.log(err);
    }
  };

  async function handleFetchUserAttributes() {
    try {
      dispatch(setLoading(true));
      const userAttributes = await fetchUserAttributes();
      console.log('the user Attribute are::', userAttributes);
      const userGroups = await currentAuthenticatedUser();
      const userObjectString = JSON.stringify(userAttributes);
      if (userAttributes) {
        await fetchData(userAttributes?.sub, userAttributes);
      }
      dispatch(setUserInfo({ status: userObjectString }));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  const onSignIn = async (event) => {
    event.preventDefault();
    if (!email && !password) {
      setPasswordError(true);
      setPassErrorMessage('Password required');
      setEmailError(true);
      setEmailErrorMessage('Email required');
      return;
    }
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage('Email required');
      return;
    }
    if (!password) {
      setPasswordError(true);
      setPassErrorMessage('Password required');
      return;
    }
    localStorage.clear();
    try {
      dispatch(setLoading(true));
      const user = await signIn({
        username: email,
        password: password,
      });
      dispatch(setLoading(false));
      if (user.isSignedIn) {
        handleFetchUserAttributes();
      }
    } catch (error) {
      dispatch(setLoading(false));
      setServerError(true);
      setServerErrorMessage(error.message);
      console.log(error.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail.replace(/\s{2,}/g, ' '));
    setEmailError(false);
    setEmailErrorMessage('');
    setServerError(false);
    setServerErrorMessage('');
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        backgroundColor: '#F5F5F5',
      }}
    >
      <Box
        component='img'
        src={LoginBG}
        alt='login_bg'
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          inset: 0,
          objectFit: 'cover',
          objectPosition: 'center',
          opacity: 0.5,
        }}
      />
      <Box
        component='form'
        onSubmit={onSignIn}
        sx={{
          width: {
            xs: '60%',
            lg: 740,
          },
          p: 5,
          borderRadius: 3,
          border: '1px solid #EEEEEE',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            pb: 2.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid #EEEEEE',
          }}
        >
          <Box
            component='img'
            src={Logo}
            alt='logo'
            sx={{
              maxWidth: 320,
              width: { xs: '100%', md: 320 },
              height: 'auto',
            }}
          />
        </Box>
        <Box
          sx={{
            mt: 5,
            mb: 6.25,
            px: {
              xs: 0,
              lg: 12.5,
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: 2.5,
          }}
        >
          <TextField
            label='Email'
            id='email'
            name='email'
            type='email'
            value={email}
            onChange={(e) => handleChangeEmail(e)}
            error={emailerror}
            helperText={emailerror ? emailerrorMessage : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <TextField
            label='Password'
            id='password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPassErrorMessage('');
              setPasswordError(false);
              setServerError(false);
              setServerErrorMessage('');
            }}
            error={passworderror}
            helperText={passworderror ? passerrorMessage : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    onClick={handleTogglePasswordVisibility}
                    sx={{
                      minWidth: 0,
                      m: 0,
                      p: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        showPassword
                          ? faEyeSlash
                          : faEye
                      }
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              color: '#999999',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: 1.25,
              textAlign: 'center',
            }}
          >
            Don’t have an account?{' '}
            <Link
              href='/signup'
              sx={{
                color: '#187DF0',
                fontWeight: 700,
              }}
            >
              Sign Up
            </Link>
          </Typography>
          <Button
            type='submit'
            sx={{
              py: 2,
              px: 6.625,
              color: '#FFFFFF',
              fontWeight: 700,
              fontSize: 12,
              lineHeight: 1.25,
              textTransform: 'initial',
              border: '1px solid #EEEEEE',
              borderRadius: 1.5,
              backgroundColor: '#000042',
              '&:hover': {
                backgroundColor: '#000042',
              },
            }}
          >
            Sign In <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Box>
      </Box>
      <Typography
        sx={{
          color: '#999999',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: 1.25,
          textAlign: 'center',
        }}
      >
        © 2024 Rothbright Inc. All Rights Reserved.
      </Typography>
      {servererror ? (
        <Box sx={{ mb: '0.7rem' }}>
          <Alert severity='error'>{servererrorMessage}</Alert>
        </Box>
      ) : null}
    </Container>
  );
}