import React, { useEffect, useState } from "react";
import { Box, Button, Card, Divider, Grid, Modal, Paper, Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { generateClient, post } from "aws-amplify/api";
import {
  getWorkspaceInfo,
  listCartItems,
  listMyLinks,
  listSubscriptions,
  listUserInfos,
  listWorkspaceInfos,
} from "../graphql/queries";
import Skeleton from "@mui/material/Skeleton";
import { PulseLoader } from "react-spinners";
import Stack from "@mui/material/Stack";
import {
  selectLoading,
  selectUserDetails,
  setLoading,
  setUserDetails,
  showToast,
  selectPageSpeedData,
  selectPageSpeedLastChecked,
  setPageSpeedData,
  selectPageSpeedLoading,
  setPageSpeedLoading,
} from "../redux/userSlice";
import { Link, useNavigate } from "react-router-dom";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { Navigate } from "react-router-dom";
import { AnalyticsBrowser } from "@june-so/analytics-next";
import "../Css/Dashboard.css";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import Advisor from "../Assets/1721063651853.jpg";
import { runGTMetrixTest } from "../components/gt-matrix";
import { getSamRushProject } from "../utills/SamRushApis/samrushApi";
import { LoadingScreen } from "../components/LoadingSiteAudit";
import CircularGauge from "../components/CircularGauge";
import PerformanceMetrics from "../components/PerformanceMetrics";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "0.5rem",
};
const videoIcon = {
  color: "#0D0C0C",
  fontSize: "1.3rem",
};
const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "1rem 1.5rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "330px",
  borderRadius: "10px",
};
const cardTitle = {
  fontSize: "1.5rem",
  margin: "0px",
  marginTop: "0rem",
};
const cardDesc = {
  lineHeight: "1.65rem",
  color: "#605E61",
};
const buttonStyle = {
  border: "none",
  color: "#000000de",
  textTransform: "initial",
  fontSize: "1rem",
  backgroundColor: "#F0F0F0",
};
const statusStyleActive = {
  color: "#1C984D",
  fontWeight: "bold",
  backgroundColor: "#D8EBDD",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};
const statusStyleInActive = {
  color: "#E35244",
  fontWeight: "bold",
  backgroundColor: "#f6cbc6",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};
const rowAlign = {
  display: "flex",
  justifyContent: "space-between",
};
const avsiorContainer = {
  display: "flex",
  justifyContent: "space-between",
};
const apiUsername = process.env.REACT_APP_GT_MATRIX_Username;
const apiKey = process.env.REACT_APP_GT_MATRIX_KEY;

const Dashboard = () => {
  const [userDetail, setUserDetail] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const userInfo = useSelector(selectUserDetails);
  const pageSpeedMetrics = useSelector(selectPageSpeedData);
  const [isPageSpeedCallInProgress, setIsPageSpeedCallInProgress] = useState(false);
  const pageSpeedLoading = useSelector(selectPageSpeedLoading);

  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const client = generateClient();
  const [projectData, setProjectData] = useState(null);
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [domain, setDomain] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageSpeedData = useSelector(selectPageSpeedData);
  const lastChecked = useSelector(selectPageSpeedLastChecked);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [listTeamUsers, setListTeamUsers] = useState(null);
  const [linkList, setLinkList] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);

        const res = await fetchUserData(selectedWorkspaceId);
        const result = res?.filter(
          (id) => id.workspace_id === selectedWorkspaceId
        );
        setUserDetail(result);
        dispatch(setUserDetails(result));
        // await runGTMetrixTest('https://www.rothbright.com', apiUsername, apiKey);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.error("Error creating todo:", error);
      }
    };

    fetchData();
  }, []);
  const fetchUserData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        user_id: {
          eq: userData.sub,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserInfos,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserInfos.nextToken;
    } while (nextToken);

    return allItems;
  };

  React.useEffect(() => {
    if (userDetail?.length) {
      fetchCartItems();
      WorkspaceInfoWithTeams();
    }
  }, [userDetail]);


  useEffect(() => {


    fetchWorkspaceInfo();
  }, [selectedWorkspaceId, projectId]);
  const fetchWorkspaceInfo = async () => {
    try {
      if (!selectedWorkspaceId) return;

      const response = await client.graphql({
        query: getWorkspaceInfo,
        variables: {
          id: selectedWorkspaceId,
        },
      });

      const workspaceData = response.data.getWorkspaceInfo;
      setDomain(workspaceData?.samrushDomainName)

      // Check if call is already in progress
      if (isPageSpeedCallInProgress) {
        return;
      }

      // Check if we have recent data
      const isDataRecent = lastChecked &&
        (new Date() - new Date(lastChecked)) < 24 * 60 * 60 * 1000;

      if (pageSpeedData && isDataRecent) {
        return pageSpeedData;
      }

      setIsPageSpeedCallInProgress(true);
      dispatch(setPageSpeedLoading(true));

      try {
        const api_key = 'AIzaSyBtL911PwgosKvUClz7OnUpf9V8PCEknDs'
        const response = await axios.get(
          `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
            workspaceData?.samrushDomainName
          )}&key=${api_key}&category=performance&category=accessibility&category=best-practices&category=seo`
        );

        const { data } = response;
        const metrics = {
          performance: Math.round((data.lighthouseResult.categories.performance?.score || 0) * 100),
          accessibility: Math.round((data.lighthouseResult.categories.accessibility?.score || 0) * 100),
          bestPractices: Math.round((data.lighthouseResult.categories['best-practices']?.score || 0) * 100),
          seo: Math.round((data.lighthouseResult.categories.seo?.score || 0) * 100)
        };

        dispatch(setPageSpeedData(metrics));
        return metrics;
      } catch (error) {
        console.error("Error fetching PageSpeed data", error);
        dispatch(showToast({
          message: "Domain name is missing!",
          type: "error"
        }));
        return null;
      } finally {
        dispatch(setPageSpeedLoading(false));
        setIsPageSpeedCallInProgress(false);
      }


    } catch (error) {
      console.error("Error fetching workspace info:", error);
    }
  };
  React.useEffect(() => {
    WorkspaceInfoWithTeams();
  }, []);

  const WorkspaceInfoWithTeams = async () => {
    setLoader(true);
    try {
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const data = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const userIdis = data.data.listWorkspaceInfos.items?.[0]?.user_id;
      if (userIdis) {
        const userPromises = userIdis.map(async (id) => {
          const variables = {
            filter: {
              user_id: {
                eq: id,
              },
              workspace_id: {
                eq: selectedWorkspaceId,
              },
            },
            limit: 1000,
          };
          const getUserData = await client.graphql({
            query: listUserInfos,
            variables: variables,
          });
          return getUserData?.data?.listUserInfos?.items[0];
        });
        const usersData = await Promise.all(userPromises);
        // const filteredData = usersData?.filter(
        //   (user) => user?.role !== "super_admin"
        // );
        setListTeamUsers(usersData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  useEffect(() => {
    getSubscripition();
    getLinksData();
  }, []);

  const getSubscripition = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getSubscriptions = await client.graphql({
        query: listSubscriptions,
        variables: variables,
      });
      const result = getSubscriptions.data.listSubscriptions.items;
      const filteredResult = result?.filter((item) => item.status === true);
      setSubscriptionsList(filteredResult);
    } catch (error) {
      setLoader(false);
      console.error("Error creating todo:", error);
    }
  };

  const fetchCartItems = async () => {
    try {
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: variables,
      });
      const res = getCartItems.data.listCartItems.items;
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
    } catch (error) {
      setLoader(false);
      console.error("Error creating todo:", error);
    }
  };

  const getLinksData = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const result = await client.graphql({
        query: listMyLinks,
        variables: variables,
      });
      setLinkList(result.data.listMyLinks.items);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  if (!selectedWorkspaceId) {
    return <Navigate to="/choose-organization" replace />;
  }

  let analytics = AnalyticsBrowser.load({
    writeKey: "2fwEgI0bB0meFBAI",
  });


  return (
    <div style={container} className="main-container">
      {/* <button onClick={getUsers}>Get</button> */}
      {loading ? (
        <Stack spacing={4}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"30%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"70%"}
              height={300}
              animation="wave"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
            <Skeleton
              variant="rounded"
              width={"33%"}
              height={300}
              animation="wave"
            />
          </div>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={50}
            animation="wave"
          />
        </Stack>
      ) : (
        <Grid container spacing={0}>

          <Grid container spacing={3}>

            {/* Site Health Gauge */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <h4 style={cardTitle} className="card-title">
                  {userData?.role !== "super_admin" ||
                    (userData?.role === "super_admin" && userInfo?.length > 0)
                    ? `Welcome, ${userInfo?.[0]?.user_name_first}!`
                    : `Welcome, Super Admin!`}
                </h4>
                <p style={cardDesc} className="card-desc">
                  <b>Rothbright</b> lets you manage all of your digital services
                  in one convenient place. We know it can be difficult to keep
                  track of all the moving parts of your digital presence, so
                  we've made it easy to manage everything in one simple
                  subscription.
                </p>
                {/* <Button
                  variant="outlined"
                  style={buttonStyle}
                  onClick={handleOpenModal}
                >
                  Welcome Video&nbsp;
                  <PlayCircleIcon style={videoIcon} />
                </Button> */}
              </Card>
              {/* <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="welcome-video-modal"
                aria-describedby="welcome-video-description"
                className={classes.modal}
                disableBackdropClick // Optional: Prevent closing on backdrop click
              >
                <div className={classes.modalContent}>
                  <h3 id="welcome-video-modal">Welcome Video</h3>
                  <p id="welcome-video-description">
                    This video provides an overview of Rothbright Dashboard
                    features.
                  </p> */}
              {/* Replace with your video source */}
              {/* <video className={classes.video} controls autoPlay muted>
                    <source
                      src="/src/Assets/invideo-ai-1080 Exploring Rothbright_ Your Ultimate Work 2024-02-29.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video> */}
              {/* </div> */}
              {/* </Modal> */}
            </Grid>
            <Grid item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={8}
              className="team-container">
              {
                pageSpeedLoading ? <Card style={organizationCard} className="dashboard-section-card">
                  <Typography variant="h6">Health Check</Typography>
                  <Grid container spacing={2}>
                    {[1, 2, 3, 4].map((item) => (
                      <Grid item xs={12} sm={6} md={3} key={item}>
                        <Skeleton variant="circular" width={80} height={80} />
                        <Skeleton variant="text" width={100} sx={{ mt: 1 }} />
                      </Grid>
                    ))}
                  </Grid>
                </Card> : <Card style={organizationCard}>

                  <PerformanceMetrics data={pageSpeedMetrics} domain={domain} setDomain={setDomain} fetchWorkspaceInfo={fetchWorkspaceInfo} selectedWorkspaceId={selectedWorkspaceId} />
                </Card>
              }

            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              className="team-container"
            >
              <Card
                style={organizationCard}
                className="dashboard-section-card team-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.3rem",
                  }}
                >
                  <h4 style={cardTitle} className="card-title">
                    Team
                  </h4>
                  <Button
                    variant="outlined"
                    style={buttonStyle}
                    onClick={() => navigate("/team")}
                  >
                    Manage Team
                  </Button>
                </div>
                <div style={rowAlign}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      {listTeamUsers?.length > 0 &&
                        listTeamUsers?.slice(0, 3).map((team, index, array) => (
                          <>
                            <div style={{ display: "flex", gap: "0.5rem" }}>
                              <img
                                src={team?.display_picture}
                                height="50px"
                                width="50px"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "start",
                                }}
                              >
                                <div>
                                  <h3
                                    style={{
                                      margin: "0px",
                                      textAlign: "start",
                                      whiteSpace: isMobile && "nowrap",
                                      overflow: isMobile && "hidden",
                                      textOverflow: isMobile && "ellipsis",
                                      maxWidth: isMobile && "250px",
                                    }}
                                  >
                                    {team?.user_name_first}&nbsp;
                                    {team?.user_name_second}
                                  </h3>
                                </div>
                                <div style={{ color: "#3b3b3b" }}>
                                  {team?.email}
                                </div>
                              </div>
                            </div>
                            {index !== array?.length - 1 && (
                              <Divider
                                light
                                style={{ margin: "0.5rem 0rem" }}
                              />
                            )}
                          </>
                        ))}
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Card
                style={organizationCard}
                className="subscription-container "
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1.3rem",
                  }}
                  className="subscription-sub-container"
                >
                  <h4 style={cardTitle} className="card-title">
                    My Plans
                  </h4>
                  <Button
                    variant="outlined"
                    style={buttonStyle}
                    onClick={() => navigate("/subscription")}
                    className="manage-sub-btn"
                  >
                    Manage Plan
                  </Button>
                </div>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "150px",
                      alignSelf: "center",
                    }}
                  >
                    <PulseLoader color="#ACD6EB" />
                  </div>
                ) : (
                  <>
                    {subscriptionsList && subscriptionsList.length > 0
                      ? subscriptionsList
                        ?.slice(0, 3)
                        .map((item, index, array) => (
                          <>
                            <div style={rowAlign} key={index}>
                              <Grid container spacing={0}>
                                <Grid item xs={9}>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <img
                                      src={item.product_image}
                                      style={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "8%",
                                        backgroundColor: "#dddddd",
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "start",
                                      }}
                                    >
                                      <div>
                                        <h3
                                          style={{
                                            margin: "0px",
                                            textAlign: "start",
                                          }}
                                        >
                                          {item.product_name}
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={
                                      item.status === true
                                        ? statusStyleActive
                                        : statusStyleInActive
                                    }
                                  >
                                    {item.status ? "Active" : "InActive"}
                                  </p>
                                </Grid>
                              </Grid>
                            </div>
                            {index !== array?.length - 1 && (
                              <Divider
                                light
                                style={{ margin: "0.5rem 0rem" }}
                              />
                            )}
                          </>
                        ))
                      : null}
                    {subscriptionsList &&
                      subscriptionsList.length === 0 &&
                      !loader && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              minHeight: "150px",
                            }}
                            className="sub-btn-container"
                          >
                            <span className="shop-text">
                              You haven't started your plan.
                            </span>
                            <Button
                              variant="contained"
                              style={{
                                marginTop: "0.5rem",
                                textTransform: "inherit",
                              }}
                              onClick={() => navigate("/shop")}
                              className="sub-button"
                            >
                              Build Your Plan
                            </Button>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card style={organizationCard} className="dashboard-section-card">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "3rem",
                  }}
                >
                  <h4 style={cardTitle} className="card-title">
                    Advisor
                  </h4>
                  <Link
                    to={"https://calendly.com/d/cpng-nc4-t7n/rothbright-advisor-calendar"}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                    target="_blank"
                  >
                    <Button variant="outlined" style={buttonStyle}>
                      Schedule Meeting
                    </Button>
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={avsiorContainer}>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <img
                            src={Advisor}
                            height="100px"
                            width="100px"
                            style={{
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "start",
                            }}
                          >
                            <div>
                              <h3
                                style={{
                                  margin: "0px",
                                  textAlign: "start",
                                }}
                              >
                                Marcus Frye
                              </h3>
                            </div>
                            <div style={{ color: "#3b3b3b" }}>
                              marcus@rothbright.com
                            </div>
                            <div style={{ color: "#3b3b3b" }}>
                              Los Angeles, CA
                            </div>
                            <div style={{ color: "#939393" }}>Pacific Time</div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3}>



          </Grid>
          <div className="space"></div>
          <Grid container spacing={5} className="footer-container">
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
