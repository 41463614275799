import TagManager from "react-gtm-module";
const tagManagerEvents = (eventName, eventData = {}) => {
  // console.warn(
  //   "this is event name",
  //   eventName,
  //   "variable ",
  //   product_name_variable
  // );
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      ...eventData,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
const landingTagMangerEvent = (eventName, utm_source) => { };
export default tagManagerEvents;
