import React, { useEffect, useState } from "react";
import ProductsMainCard from "../components/ProductMainCard";
import ProductCarousel from "../components/Common/ProductCarousel";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useJune } from "./JuneSdk";
import ShopFooter from "../components/ShopFooter";
import {
  listProductCards,
  listProductCategories,
  listProductTags,
  listProductVariations,
} from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useMediaQuery } from "@mui/material";
import tagManagerEvents from "./GoogleTagManager";
import googleTagTriggers from '../utills/GoogleTagsList/google-tags.json'


const ProductScreen = () => {
  const client = generateClient();
  const { state } = useLocation();
  const analytics = useJune("xcngUCruinFOUefl");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isCartPressed, setIsCartPressed] = useState(false);
  const [isCheckoutPressed, setIsCheckOutPressed] = useState(false);
  const [productDetail, setProductDetail] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [isShopItem, setIsShopItem] = useState(false);
  const [productId, setProductId] = useState("");
  const [page, setPage] = useState(1);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [sliderValue, setSliderValue] = useState([0, 100000]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCompactLayout, setIsCompactLayout] = useState(false);
  const [itemsPerRow, setItemsPerRow] = useState(4);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showCategories, setShowCategories] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [buyNowProduct, setBuyNowProduct] = useState([]);
  const [categoriesNames, setCategoriesNames] = useState([]);
  const [brandsName, setBrandsName] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const container = {
    margin: "2rem 4rem",
  };

  const productsPerPage = isCompactLayout ? 6 : 10;
  useEffect(() => {
    tagManagerEvents(googleTagTriggers.externalProductPage)
    document.title = "Shop";
    const initializeCartItems = () => {
      const storedCartItems =
        JSON.parse(localStorage.getItem("productCart")) || [];
      setCartItems(storedCartItems);
    };

    initializeCartItems();

    if (state?.fromSinglePage) {
      setIsCartPressed(true);
    }

    if (products?.length === 0 || products === null || products === undefined) {
      fetchProducts();
    }
  }, [state, products]);

  useEffect(() => {
    if (
      minPrice === "" ||
      maxPrice === "" ||
      isNaN(parseFloat(minPrice)) ||
      isNaN(parseFloat(maxPrice))
    ) {
      return;
    }

    const filtered = products.filter((product) => {
      const price = parseFloat(product.price);
      return price >= parseFloat(minPrice) && price <= parseFloat(maxPrice);
    });
    setFilteredProducts(filtered);
    setPage(1);
  }, [minPrice, maxPrice, products]);

  useEffect(() => {
    const filtered = products?.filter((product) => {
      return product?.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredProducts(filtered);
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (selectedCategory === "") {
      return;
    }

    const filtered = products.filter((product) => {
      return (
        product.categories &&
        product.categories.some(
          (category) => category.name === selectedCategory
        )
      );
    });
  }, [selectedCategory, products]);

  const fetchProducts = async () => {
    try {
      dispatch(setLoading(true));
      const getProductsData = await client.graphql({
        query: listProductCards,
      });
      const productResponse = getProductsData.data.listProductCards.items;
      const getCategoriesData = await client.graphql({
        query: listProductCategories,
      });
      const categoryResponse =
        getCategoriesData.data.listProductCategories.items;

      const categoryMap = {};
      categoryResponse.forEach((category) => {
        categoryMap[category.id] = category.name;
      });

      const getTagsData = await client.graphql({
        query: listProductTags,
      });
      const tagsResponse = getTagsData.data.listProductTags.items;

      const tagMap = {};
      tagsResponse.forEach((tag) => {
        tagMap[tag.id] = tag.name;
      });

      const cleanedResponse = productResponse.map((item) => {
        const cleanedItem = { ...item };

        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories).map(
            (categoryId) => ({
              name: categoryMap[categoryId],
            })
          );
        }

        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags).map((tag) => ({
            name: tagMap[tag],
          }));
        }

        return cleanedItem;
      });
      setProducts(cleanedResponse);
      setFilteredProducts(cleanedResponse);
      await fetchPlansById(cleanedResponse);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  async function fetchAllProductVariations() {
    let allItems = [];
    let nextToken = null;

    do {
      const getProductsData = await client.graphql({
        query: listProductVariations,
        variables: {
          nextToken,
        },
      });

      const response = getProductsData.data.listProductVariations.items;
      allItems = [...allItems, ...response];
      nextToken = getProductsData.data.listProductVariations.nextToken;
    } while (nextToken);

    return allItems;
  }

  const fetchPlansById = async (products) => {
    try {
      dispatch(setLoading(true));
      const response = await fetchAllProductVariations();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });

      const updatedProductData = products.map((product) => {
        const matchedPlans = cleanedResponse.filter(
          (plan) => plan.product_id === product.id
        );
        const lowestPrice = Math.min(
          ...matchedPlans.map((plan) => Number(plan.price))
        );
        return {
          ...product,
          plans: matchedPlans.filter(
            (plan) => Number(plan.price) === lowestPrice
          ),
        };
      });
      setProducts(updatedProductData);
      setFilteredProducts(updatedProductData);
      const categoriesName = Array.from(
        new Set(
          updatedProductData
            ?.flatMap((product) =>
              Array.isArray(product?.categories)
                ? product.categories.map((category) => category.name)
                : []
            )
            ?.filter((category) => typeof category === "string")
        )
      );
      setCategoriesNames(categoriesName);
      const brandsCategory = Array.from(
        new Set(
          updatedProductData
            ?.flatMap((product) =>
              Array.isArray(product?.tags)
                ? product.tags.map((category) => category.name)
                : []
            )
            ?.filter((brands) => typeof brands === "string")
        )
      );

      setBrandsName(brandsCategory);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onAddToCart = (product) => {
    const existingItemIndex = cartItems.findIndex(
      (item) => item.id === product.id && item.plan === product.plan
    );
    const quantityToAdd = product.quantity || 1;

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex] = {
        ...updatedCartItems[existingItemIndex],
        quantity: updatedCartItems[existingItemIndex].quantity + quantityToAdd,
      };
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...product, quantity: quantityToAdd }]);
    }
  };
  const getTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalAmount = () => {
    let _totalPrice = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return _totalPrice;
  };
  const getSubtotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const onHandleCartPressed = (_flag) => {
    localStorage.setItem("productCart", JSON.stringify(cartItems));
    trackButtonClick("cartPressed");
    if (cartItems.length > 0) {
      tagManagerEvents("cartIconPressed");
      navigate("/cart-item");
    }
  };

  const handleCheckOutPressed = (productDetail, totalPrice) => {
    setProductDetail(productDetail);
    setTotalPrice(totalPrice);
    setIsCheckOutPressed(true);
  };
  const trackButtonClick = (buttonName) => {
    if (analytics) {
      analytics.track("Button Clicked", {
        button_name: buttonName,
      });
    }
  };
  const onItemPressed = (productSlug, productName, productPrice) => {
    tagManagerEvents(googleTagTriggers.externalProductDetail)
    try {
      navigate(`/product/${productSlug}`, {
        state: { price: productPrice },
      });
    } catch (error) {
      console.log("onItemPressed error:", error);
    }
  };

  const handleClearFilter = () => {
    setPage(1);
    setMinPrice("");
    setMaxPrice("");
    setSearchQuery("");
    setSelectedCategory("");
    setSelectedCategories([]);
    setSelectedBrands([]);
    setFilteredProducts(products);
    setSliderValue([0, 10000]);
    setCartItems([]);
    setIsCartPressed(false);
    setIsCheckOutPressed(false);
  };

  const handleMinPriceChange = (event) => {
    const value = event.target.value;
    setMinPrice(value);
  };

  const handleMaxPriceChange = (event) => {
    const value = event.target.value;
    setMaxPrice(value);
  };

  // Slider change handler
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };
  // Search bar change handler
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Category dropdown change handler
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const filtered = products.filter((product) => {
      return (
        product.categories &&
        product.categories.some((cat) => cat.name === category)
      );
    });
    setFilteredProducts(filtered);
  };

  const handleToggleCategories = () => {
    setShowCategories(!showCategories);
  };
  const handleToggleCompactLayout = () => {
    setIsCompactLayout(false);
    setItemsPerRow(3);
  };
  const handleToggleListLayout = () => {
    setIsCompactLayout(true);
    setItemsPerRow(4);
  };
  const updateTotalPrice = (_totalPrice) => {
    getTotalAmount();
    setTotalPrice(_totalPrice);
  };

  const handleBackWardPressed = () => {
    if (isCheckoutPressed) {
      setIsCheckOutPressed(false);
    } else if (isCartPressed) {
      setIsCartPressed(false);
    } else if (isShopItem && !isBuyNow) {
      setIsShopItem(false);
    } else if (isBuyNow) {
      setIsBuyNow(false);
    } else {
      setIsCartPressed(false);
      setIsCheckOutPressed(false);
    }
  };

  const handleForwardPressed = () => {
    if (cartItems.length > 0 && !isCartPressed) {
      onHandleCartPressed(true);
    }
  };

  const handleBrandChange = (brand) => {
    const index = selectedBrands.indexOf(brand);
    let updatedBrands;

    if (index === -1) {
      updatedBrands = [...selectedBrands, brand];
    } else {
      updatedBrands = [...selectedBrands];
      updatedBrands.splice(index, 1);
    }

    setSelectedBrands(updatedBrands);

    if (updatedBrands.length === 0) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => {
        return (
          product.tags &&
          product.tags.some((tag) => updatedBrands.includes(tag.name))
        );
      });
      setFilteredProducts(filtered);
    }
  };

  const handleBrandChangeMobile = (brands) => {
    brands = Array.isArray(brands) ? brands : [brands];
    setSelectedBrands(brands);
    if (brands.length === 0) {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) => {
        return (
          product.tags && product.tags.some((tag) => brands.includes(tag.name))
        );
      });
      setFilteredProducts(filtered);
    }
  };
  const updateCartItems = (updatedCartItems) => {
    setCartItems(updatedCartItems);
  };
  const onBuyNowPressed = (_product, _totalPrice) => {
    setIsBuyNow(true);
    setBuyNowProduct(_product);
    setTotalPrice(_totalPrice);
  };

  return (
    <>
      <ProductsMainCard
        products={products}
        categoriesNames={categoriesNames}
        cartItems={cartItems}
        isCartPressed={isCartPressed}
        isCheckoutPressed={isCheckoutPressed}
        productDetail={productDetail}
        totalPrice={totalPrice}
        isShopItem={isShopItem}
        productId={productId}
        page={page}
        filteredProducts={filteredProducts}
        sliderValue={sliderValue}
        searchQuery={searchQuery}
        selectedCategory={selectedCategory}
        showCategories={showCategories}
        isCompactLayout={isCompactLayout}
        itemsPerRow={itemsPerRow}
        productsPerPage={productsPerPage}
        selectedBrands={selectedBrands}
        minPrice={minPrice}
        maxPrice={maxPrice}
        selectedCategories={selectedCategories}
        isBuyNow={isBuyNow}
        buyNowProduct={buyNowProduct}
        brandsName={brandsName}
        handleClearFilter={handleClearFilter}
        handleCheckOutPressed={handleCheckOutPressed}
        onAddToCart={onAddToCart}
        totalItems={getTotalItems()}
        totalAmount={getTotalAmount()}
        onHandleCartPressed={onHandleCartPressed}
        onItemPressed={onItemPressed}
        handleChangePage={handleChangePage}
        getSubtotal={getSubtotal()}
        handleMinPriceChange={handleMinPriceChange}
        handleMaxPriceChange={handleMaxPriceChange}
        handleSliderChange={handleSliderChange}
        handleSearchChange={handleSearchChange}
        handleCategoryChange={handleCategoryChange}
        handleToggleCategories={handleToggleCategories}
        handleToggleCompactLayout={handleToggleCompactLayout}
        handleToggleListLayout={handleToggleListLayout}
        updateTotalPrice={updateTotalPrice}
        handleForwardPressed={handleForwardPressed}
        handleBackWardPressed={handleBackWardPressed}
        handleBrandChange={handleBrandChange}
        updateCartItems={updateCartItems}
        onBuyNowPressed={onBuyNowPressed}
        handleBrandChangeMobile={handleBrandChangeMobile}
      />
      {!isMobile && !isCartPressed && !isCheckoutPressed && !isShopItem && (
        <div className="carouselContainer" style={container}>
          <ProductCarousel products={products} />
        </div>
      )}
      {!isMobile && (
        <div style={container}>
          <ShopFooter />
        </div>
      )}
    </>
  );
};

export default ProductScreen;
