import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import SpeedIcon from "@mui/icons-material/Speed";
import ForumIcon from "@mui/icons-material/Forum";
import FolderIcon from "@mui/icons-material/Folder";
import GroupIcon from "@mui/icons-material/Group";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useMediaQuery } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WidgetsIcon from "@mui/icons-material/Widgets";
import DescriptionIcon from "@mui/icons-material/Description";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { InsightsOutlined } from "@mui/icons-material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
const drawerWidth = 100;

const iconAlign = {
  display: "flex",
  justifyContent: "center",
  height: "90px",
};
const iconStyle = {
  fontSize: "2.1rem",
  width: "100%",
};

const linkStyle = {
  textDecoration: "none",
  color: "black",
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const useStyles = makeStyles({
  sidebarTopIcon: {
    color: "#000",
  },
  sideBarText: {
    color: "#000",
  },
  sidebarSubmenuText: {
    color: "#a48d31",
  },
});

export default function DrawerComponent() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);

  const handleIconClick = (index, route) => {
    setSelectedIndex(index);
    navigate(route);
  };

  const list = [
    {
      name: "Dashboard",
      route: "/home",
      icon: <SpeedIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    {
      name: "Wiki",
      route: "/wiki",
      icon: <ImportContactsIcon />,
      allowedRoles: ["super_admin"],
    },
    // {
    //   name: "Performance",
    //   route: "/performance",
    //   icon: <InsightsOutlined />,
    //   allowedRoles: [
    //     "super_admin",
    //     "workspace_admin",
    //     "advisor",
    //     "workspace_member",
    //   ],
    // },
    {
      name: "Ticket Request",
      route: "/ticket-request",
      icon: <ConfirmationNumberIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    {
      name: "Team",
      route: "/team",
      icon: <GroupIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    {
      name: "Plan",
      route: "/subscription",
      icon: <WidgetsIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    {
      name: "Shop",
      route: "/shop",
      icon: <ShoppingCartIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    // {
    //   name: "Template",
    //   route: "/template",
    //   icon: <DescriptionIcon />,
    //   allowedRoles: ["super_admin", "workspace_admin", "advisor"],
    // },
    {
      name: "Store Manager",
      route: "/store_management",
      icon: <StorefrontIcon />,
      allowedRoles: ["super_admin"],
    },
    {
      name: "My Links",
      route: "/links",
      icon: <OpenInNewIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },
    {
      name: "Workspace Statistics",
      route: "/workspace-statistics",
      icon: <StackedBarChartIcon />,
      allowedRoles: ["super_admin"],
    },
    {
      name: "Settings",
      route: "/profile-setting",
      icon: <SettingsIcon />,
      allowedRoles: [
        "super_admin",
        "workspace_admin",
        "advisor",
        "workspace_member",
      ],
    },

  ];

  // Use a media query to determine if the screen is small (mobile view)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Conditionally render the Drawer component based on screen size
  if (isSmallScreen) {
    return null; // Don't render anything if it's a small screen
  }

  return (
    <Drawer variant="permanent" style={{ height: "100vh" }}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
        className={classes.sidebarTop}
      ></Toolbar>
      <Divider />
      <List component="nav" sx={{ mt: 1 }}>
        {list?.map((item, index) => {
          if (item.allowedRoles.includes(userData?.role)) {
            const tooltipText = item.name;

            return (
              <Tooltip
                key={index}
                title={tooltipText}
                placement="right"
                arrow
                TransitionComponent={Zoom}
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                      {
                        marginTop: "0px",
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                      {
                        marginBottom: "0px",
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                      {
                        marginLeft: "0px",
                      },
                      [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                      {
                        marginRight: "0px",
                      },
                    },
                  },
                }}
              >
                <Link to={item.route} key={index} style={linkStyle}>
                  <ListItemButton
                    style={{
                      ...iconAlign,
                      backgroundColor:
                        selectedIndex === index ? "#f0f0f0" : "inherit",
                    }}
                    onClick={() => handleIconClick(index, item.route)}
                  >
                    <ListItemIcon style={{ color: "#0D0C0C" }}>
                      {React.cloneElement(item.icon, { style: iconStyle })}
                    </ListItemIcon>
                  </ListItemButton>
                </Link>
              </Tooltip>
            );
          } else {
            return null;
          }
        })}
      </List>
    </Drawer>
  );
}
