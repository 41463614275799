import React from 'react';
import { Box, Typography } from '@mui/material';

const PageSpeedGuage = ({ value, size, thickness }) => {
    const radius = (size - thickness) / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (value / 100) * circumference;

    return (
        <Box sx={{
            position: 'relative',
            display: 'inline-flex',
            width: size,
            height: size
        }}>
            <svg
                viewBox={`0 0 ${size} ${size}`}
                style={{ transform: 'rotate(-90deg)' }}
            >
                {/* Background circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="none"
                    stroke="#f0f0f0"
                    strokeWidth={thickness}
                />
                {/* Progress circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="none"
                    stroke="#00B2FF"
                    strokeWidth={thickness}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                />
            </svg>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center'
            }}>
                <Typography sx={{
                    fontSize: size * 0.20,
                    fontWeight: 500
                }}>
                    {value}%
                </Typography>

            </Box>
        </Box>
    );
};

export default PageSpeedGuage;