import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";

import {
  PersonOutlineOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import tagManagerEvents from "../../screen/GoogleTagManager";
import Logo from "../../Assets/whiteLogo.png";
import LogoDrawer from "../../Assets/faviconPNG.png";
import { useMediaQuery } from "@mui/material";
const drawerWidth = 240;

const ExternalMobileAppBar = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { window } = props;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={LogoDrawer}
          alt="Logo"
          style={{
            height: "50px",
            width: "80%",
            padding: 10,
          }}
        />
      </div>
      <ListItem
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "inherit",
            }}
            onClick={() => navigate("/login")}
          >
            Log in
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "inherit",
            }}
            onClick={() => navigate("/signup")}
          >
            Create Workspace
          </Button>
        </div>
      </ListItem>
      <List>
        {props.brandsName
          ?.sort((a, b) => a.localeCompare(b))
          .map((name, index) => (
            <ListItem key={index}>
              <ListItemButton sx={{ height: "2rem" }}>
                <ListItemText
                  onClick={() => props.handleBrandChangeMobile(name)}
                  primary={name}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="static" sx={{ backgroundColor: "#232f3e" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 0 }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: "flex", maxWidth: isMobile ? "100px" : "180px" }}>
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: isMobile ? "20px" : "25px",
                objectFit: "contain"
              }}
            />
          </div>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{}}>
            <IconButton
              size="large"
              aria-label="cart"
              color="inherit"
              onClick={() => {
                navigate("/login");
              }}
              style={{ padding: 0 }}
            >
              <span style={{ fontSize: isMobile ? 14 : 14 }}>Sign in</span>{" "}
              <PersonOutlineOutlined />
            </IconButton>
            <IconButton
              size="large"
              aria-label="cart"
              color="inherit"
              onClick={() => {
                navigate("/cart-item");
                tagManagerEvents("cartIconPressed");
              }}
            >
              <Badge badgeContent={props.productCount} color="error">
                <ShoppingCartOutlined />
              </Badge>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default ExternalMobileAppBar;
