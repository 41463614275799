import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Toast from "./components/toast";
import Loader from "./components/loader";
import { Amplify } from "aws-amplify";
import awsmobile from "./aws-exports";
import TagManager from "react-gtm-module";
Amplify.configure(awsmobile);

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM- 5FDVM5BX",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routing />
        <Toast />
        <Loader />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
