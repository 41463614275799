import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    userDetails: null,
    workspceDetails: null,
    products: null,
    singleProduct: null,
    productCount: null,
    loading: false,
    toast: { message: "", type: "success" },
    pageSpeedData: null,
    pageSpeedLastChecked: null,
    pageSpeedLoading: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setWorkspaceDetails: (state, action) => {
      state.workspceDetails = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action.payload;
    },
    setProductCount: (state, action) => {
      state.productCount = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    showToast: (state, action) => {
      const { message, type } = action.payload;
      state.toast = { message, type };
    },
    clearToast: (state) => {
      state.toast = { message: "", type: "success" };
    },
    setPageSpeedData: (state, action) => {
      state.pageSpeedData = action.payload;
      state.pageSpeedLastChecked = new Date().toISOString();
    },
    setPageSpeedLoading: (state, action) => {
      state.pageSpeedLoading = action.payload;
    },
  },
});

export const {
  setUserInfo,
  setLoading,
  showToast,
  clearToast,
  setUserDetails,
  setWorkspaceDetails,
  setProducts,
  setSingleProduct,
  setProductCount,
  setPageSpeedData,
  setPageSpeedLoading
} = userSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo;
export const selectUserDetails = (state) => state.user.userDetails;
export const selectWorkspaceDetails = (state) => state.user.workspceDetails;
export const selectProducts = (state) => state.user.products;
export const selectSingleProduct = (state) => state.user.singleProduct;
export const selectProductCount = (state) => state.user.productCount;
export const selectLoading = (state) => state.user.loading;
export const selectToastMessage = (state) => state.user.toast.message;
export const selectToastType = (state) => state.user.toast.type;
export const selectPageSpeedData = (state) => state.user.pageSpeedData;
export const selectPageSpeedLastChecked = (state) => state.user.pageSpeedLastChecked;
export const selectPageSpeedLoading = (state) => state.user.pageSpeedLoading;
export default userSlice.reducer;
