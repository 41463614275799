import React, { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import {
  Card,
  Grid,
  InputAdornment,
  TextField,
  Button,
  MenuItem,
  Skeleton,
  Stack,
  IconButton,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Footer from "../components/Footer";
import Divider from "@mui/material/Divider";
import { generateClient } from "aws-amplify/api";
import { selectLoading, setLoading, showToast } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  listProductCards,
  listProductCategories,
  listProductTags,
  listProductVariations,
} from "../graphql/queries";
import { useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { PulseLoader } from "react-spinners";
import tagManagerEvents from "./GoogleTagManager";
import googleTagTriggers from '../utills/GoogleTagsList/google-tags.json'

const Shop = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const container = {
    margin: isMobile ? "0px 0px 0px 0px" : "2rem 4rem",
  };
  const title = {
    fontSize: "2rem",
    margin: "0px",
    marginBottom: "1rem",
  };
  const organizationCard = {
    margin: "1rem 0rem",
    padding: "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
  };
  const productCard = {
    border: "1px solid #DCE5EC",
    borderRadius: "0px",
    cursor: "pointer",
  };
  const gridContainer = {
    padding: "1.5rem 2rem",
  };
  const productContainer = {
    marginTop: "0rem",
  };
  const productImage = {
    width: "100%",
    objectFit: "cover",
    borderRadius: "15px",
  };
  const productName = {
    color: "#2895CF",
    margin: "0px",
    fontSize: "1.3rem",
  };
  const productContent = {
    margin: "1rem 1rem",
  };
  const productDesc = {
    fontSize: "1.1rem",
    fontWeight: "bold",
  };
  const buttonStyle = {
    border: "none",
    color: "#2895CF",
    textTransform: "initial",
    padding: "0px",
    fontSize: "0.9rem",
    fontWeight: "bold",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = generateClient();
  const loading = useSelector(selectLoading);
  const [category, setCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [search, setSearch] = useState("");
  const [product, setProduct] = useState(null);
  const [filteredResults, setFilteredResults] = useState(product);
  const [loader, setLoader] = useState(false);

  React.useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoader(true);
      const getProductsData = await client.graphql({
        query: listProductCards,
      });
      const productResponse = getProductsData.data.listProductCards.items;

      const getCategoriesData = await client.graphql({
        query: listProductCategories,
      });
      const categoryResponse =
        getCategoriesData.data.listProductCategories.items;

      const categoryMap = {};
      categoryResponse.forEach((category) => {
        categoryMap[category.id] = category.name;
      });

      const getTagsData = await client.graphql({
        query: listProductTags,
      });
      const tagsResponse = getTagsData.data.listProductTags.items;

      const tagMap = {};
      tagsResponse.forEach((tag) => {
        tagMap[tag.id] = tag.name;
      });

      const cleanedResponse = productResponse.map((item) => {
        const cleanedItem = { ...item };

        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories).map(
            (categoryId) => ({
              name: categoryMap[categoryId],
            })
          );
        }

        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags).map((tag) => ({
            name: tagMap[tag],
          }));
        }

        return cleanedItem;
      });
      const sortedResult = cleanedResponse?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const sortProductsAndCategories = sortedResult?.map((product) => {
        const newProduct = { ...product };
        newProduct.categories = newProduct.categories?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        newProduct.tags = newProduct.tags?.sort((a, b) =>
          a.name?.localeCompare(b.name)
        );
        return newProduct;
      });
      setProduct(sortProductsAndCategories);
      await fetchPlansById(cleanedResponse);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  async function fetchAllProductVariations() {
    let allItems = [];
    let nextToken = null;

    do {
      const getProductsData = await client.graphql({
        query: listProductVariations,
        variables: {
          nextToken,
        },
      });

      const response = getProductsData.data.listProductVariations.items;
      allItems = [...allItems, ...response];
      nextToken = getProductsData.data.listProductVariations.nextToken;
    } while (nextToken);

    return allItems;
  }

  const fetchPlansById = async (products) => {
    try {
      setLoader(true);
      const response = await fetchAllProductVariations();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });

      const updatedProductData = products.map((product) => {
        const matchedPlans = cleanedResponse.filter(
          (plan) => plan.product_id === product.id
        );
        const lowestPrice = Math.min(
          ...matchedPlans.map((plan) => Number(plan.price))
        );
        return {
          ...product,
          plans: matchedPlans.filter(
            (plan) => Number(plan.price) === lowestPrice
          ),
        };
      });
      setProduct(updatedProductData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    filterResults();
  }, [search, category, platform, product]);

  const filterResults = () => {
    let filtered = product?.filter((item) => {
      const titleMatches =
        !search || item.name.toLowerCase().includes(search.toLowerCase());
      const categoryMatches =
        !category || item.categories?.some((cat) => cat.name === category);
      const platformMatches =
        !platform || item.tags?.some((cat) => cat.name === platform);

      return titleMatches && categoryMatches && platformMatches;
    });

    setFilteredResults(filtered);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCategory("");
    setPlatform("");
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setSearch("");
    setPlatform("");
  };

  const handlePlatformChange = (e) => {
    setPlatform(e.target.value);
    setSearch("");
    setCategory("");
  };
  const handleTagTrigger = () => {
    tagManagerEvents(googleTagTriggers.productClicked)
  }
  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          {!isMobile && (
            <Grid item xs={12} sm={6}>
              <p style={title}>Shop</p>
            </Grid>
          )}
          <Grid item xs={12} spacing={0}>
            <Card
              style={{
                ...organizationCard,
                padding: isMobile && 0,
                margin: isMobile && 0,
              }}
            >
              <div style={gridContainer}>
                <Grid
                  container
                  spacing={2}
                  direction={isMobile ? "column" : "row"}
                >
                  <Grid item xs={4}>
                    <TextField
                      type="text"
                      size="small"
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Search style={{ color: "#777" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      size="small"
                      margin="normal"
                      fullWidth
                      name="category"
                      label="Filter by category"
                      style={{ margin: "0px" }}
                      value={category}
                      onChange={handleCategoryChange}
                      SelectProps={{
                        IconComponent: category ? () => null : undefined,
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: category && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear selection"
                              onClick={() => setCategory("")}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                      {Array.from(
                        new Set(
                          product?.flatMap((product) =>
                            product?.categories?.map((cat) => cat.name)
                          )
                        )
                      ).map((categoryName) => (
                        <MenuItem key={categoryName} value={categoryName}>
                          {categoryName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      select
                      size="small"
                      margin="normal"
                      fullWidth
                      name="category"
                      label="Filter by Platform"
                      style={{ margin: "0px" }}
                      value={platform}
                      onChange={handlePlatformChange}
                      SelectProps={{
                        IconComponent: platform ? () => null : undefined,
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: platform && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear selection"
                              onClick={() => setPlatform("")}
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                      {Array.from(
                        new Set(
                          product?.flatMap((product) =>
                            product?.tags?.map((cat) => cat.name)
                          )
                        )
                      ).map((tagName) => (
                        <MenuItem key={tagName} value={tagName}>
                          {tagName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "50vh",
                    }}
                  >
                    <PulseLoader color="#ACD6EB" />
                  </div>
                ) : (
                  <>
                    <Grid container spacing={4} style={productContainer}>
                      {filteredResults?.map((item) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={3}
                          xl={3}
                          key={item.id}
                        >
                          <Card
                            style={{
                              ...productCard,
                              marginBottom: isMobile && 70,
                              marginTop: isMobile && -20,
                            }}
                            onClick={() => {
                              handleTagTrigger();
                              navigate(`/shop/${item.slug}`);
                            }}
                          >
                            <img src={item.image} style={productImage} />
                            <div style={productContent}>
                              <h2
                                style={{
                                  ...productName,
                                  fontSize: isMobile && "1rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.name}
                              </h2>
                              {item.type === "variable subscription" ? (
                                <p
                                  style={{
                                    ...productDesc,
                                    fontSize: isMobile && "0.8rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "0.8rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    From:
                                  </span>{" "}
                                  <span
                                    style={{
                                      color: "#2895CF",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`$${Number(item?.plans?.[0]?.price)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toLocaleString("en-US")}`}
                                  </span>{" "}
                                  / {item?.plans?.[0]?.period}
                                </p>
                              ) : item.type === "simple product" ? (
                                <p
                                  style={{
                                    ...productDesc,
                                    fontSize: isMobile && "0.8rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#2895CF",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`$${Number(item.price)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toLocaleString("en-US")}`}
                                  </span>
                                </p>
                              ) : null}
                              <Divider light />
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  startIcon={<ShoppingCartIcon />}
                                  style={buttonStyle}
                                  onClick={() => { handleTagTrigger(); navigate(`/shop/${item.id}`) }}
                                >
                                  Select options
                                </Button>
                                <Button
                                  variant="outlined"
                                  startIcon={<FormatListBulletedIcon />}
                                  style={buttonStyle}
                                  onClick={() => { handleTagTrigger(); navigate(`/shop/${item.id}`) }}
                                >
                                  Details
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </div>
            </Card>
            {!isMobile && (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Footer />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div >
    </>
  );
};

export default Shop;
