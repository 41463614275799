import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  autoSignIn,
  confirmSignUp,
  fetchUserAttributes,
  resendSignUpCode,
  signUp,
} from 'aws-amplify/auth';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faEnvelope,
  faEye,
  faEyeSlash,
  faGlobe,
  faInfoCircle,
  faLayerGroup,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import { setLoading, setUserInfo, showToast } from '../redux/userSlice';
import Logo from '../Assets/faviconPNG.png';
import {
  createCartItems,
  createSubscriberCard,
  createUserBilling,
  createUserInfo,
  createWorkspaceInfo,
  createWorkspaceTeam,
  updateTeamInvitation,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
} from '../graphql/mutations';
import { listWorkspaceInfos, listWorkspaceTeams } from '../graphql/queries';
import { createStripeCustomer } from '../utills/stripeApis/stripeApi';
import uuid from 'react-uuid';
import axios from 'axios';
import { generateClient } from 'aws-amplify/api';

const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
const apiUsername = process.env.REACT_APP_GT_MATRIX_Username;
const apiKey = process.env.REACT_APP_GT_MATRIX_KEY;
const desktendLoginUrl = process.env.REACT_APP_DESKTEND_LOGIN_ENDPOINT;
const desktendFeedsUrl = process.env.REACT_APP_DESKTEND_FEED_ENDPOINT;
const desktendEmail = process.env.REACT_APP_DESKTEND_USER_EMAIL;
const desktendPassword = process.env.REACT_APP_DESKTEND_USER_PASSWORD;
const desktendDesk = process.env.REACT_APP_DESKTEND_DESK;

export default function SignUpNew() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stepRefs = useRef([]);
  const [currentStep, setCurrentStep] = useState(0);
  const client = generateClient();
  const { state } = useLocation();
  const cartItems = state?.cartItems;
  const [unlockedSteps, setUnlockedSteps] = useState(0);
  const [stepSelectorOffset, setStepSelectorOffset] = useState(0);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [combinedCode, setCombinedCode] = useState('');

  const handleCurrentStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSetWorkspace = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading(true));
      setErrors({});

      const { workspaceName } = data;

      const formErrors = {};

      if (!workspaceName || workspaceName.trim() === '') {
        formErrors.workspaceName = 'Workspace name is required';
      }

      if (Object.keys(formErrors).length === 0) {
        setData({ workspaceName });

        setUnlockedSteps(1);
        setCurrentStep(1);
      } else {
        setErrors(formErrors);
      }
    } catch (error) {
      dispatch(
        showToast({
          type: 'error',
          message: error.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    try {
      dispatch(setLoading(true));
      setErrors({});

      const { email, firstName, lastName, password, confirmPassword } =
        data;

      const formErrors = {};

      if (!isValidEmail(email)) {
        formErrors.email = 'Please type valid email';
      }

      if (!firstName || firstName.trim() === '') {
        formErrors.firstName = 'First name is required';
      }

      if (!lastName || lastName.trim() === '') {
        formErrors.lastName = 'Last name is required';
      }

      if (password !== confirmPassword) {
        formErrors.password = 'Passwords do not match';
        formErrors.confirmPassword = 'Passwords do not match';
      }

      if (!password || password.trim() === '') {
        formErrors.password = 'Password is required';
      }

      if (!confirmPassword || confirmPassword.trim() === '') {
        formErrors.confirmPassword = 'Confirm password is required';
      }

      if (Object.keys(formErrors).length === 0) {
        await signUp({
          username: email,
          password: password,
          options: {
            userAttributes: {
              email: email,
              name: firstName,
              family_name: lastName,
            },
            autoSignIn: true,
          },
        });

        setData({
          ...data,
          email,
          firstName,
          lastName,
          password,
          confirmPassword,
        });

        setUnlockedSteps(2);
        setCurrentStep(2);
      } else {
        setErrors(formErrors);
      }
    } catch (error) {
      dispatch(
        showToast({
          type: 'error',
          message: error.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleVerification = async (event) => {
    event.preventDefault();
    const e = arguments[0];
    const {
      email,
      workspaceName,
      firstName,
      lastName,
      password,
      confirmPassword,
    } = data;
    const createNewCustomer = {
      createCustomerProfileRequest: {
        merchantAuthentication: {
          name: merchant_name,
          transactionKey: transaction_key,
        },
        profile: {
          email: email,
        },
      },
    };
    let userId;
    let user_id;
    let workspaceId;
    let workspaceTeamId;
    let userBillingDetailId;
    let subscriberId;
    try {
      dispatch(setLoading(true));
      const createAuthorizeCustomer = async () => {
        const response = await axios.post(authorize_api, createNewCustomer);
        if (
          response?.data?.messages?.resultCode === "Error" &&
          response?.data?.messages?.message?.[0]?.code === "E00039"
        ) {
          const getCustomer = {
            getCustomerProfileRequest: {
              merchantAuthentication: {
                name: merchant_name,
                transactionKey: transaction_key,
              },
              email,
            },
          };
          const fetchCustomerData = await axios.post(
            authorize_api,
            getCustomer
          );
          const id = fetchCustomerData?.data?.profile?.customerProfileId;
          return id;
        } else {
          const id = response.data.customerProfileId;
          return id;
        }
      };
      const newCustomerId = await createAuthorizeCustomer();
      // const stripeCustomerId = await createStripeCustomer(email);
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: data.email,
        confirmationCode: combinedCode,
      });
      if (isSignUpComplete) {
        async function handleFetchUserAttributes() {
          try {
            dispatch(setLoading(true));
            const userAttributes = await fetchUserAttributes();
            const userObjectString = JSON.stringify(userAttributes);
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...userAttributes,
                role:
                  "workspace_admin",
              })
            );
            dispatch(setUserInfo({ status: userObjectString }));

            workspaceId = uuid();
            user_id = uuid();
            userId = userAttributes?.sub;
            workspaceTeamId = uuid();
            userBillingDetailId = uuid();
            subscriberId = uuid();
            // Desktend API integration

            // Login to Desktend
            let createFeedResponse;

            const loginResponse = await axios.post(desktendLoginUrl, {
              username: desktendEmail,
              password: desktendPassword // You'll need to determine how to securely handle the password
            });
            console.log("The login response of desktent api is:::", loginResponse)
            if (loginResponse.data.accessToken) {
              // Create empty feed in Desktend
              createFeedResponse = await axios.post(desktendFeedsUrl, {
                name: workspaceName,
                mode: "Regular"
                // Add any other required fields for creating a feed
              }, {
                headers: {
                  'Authorization': `Bearer ${loginResponse.data.accessToken}`,
                  'Desk': desktendDesk
                }
              });

              if (createFeedResponse.data) {
                console.log('Empty feed created successfully in Desktend');
              }
            }


            // await postCartItems(userId, workspaceId);
            await postSubscriberID(
              workspaceId,
              subscriberId,
              newCustomerId,
              // stripeCustomerId
            );
            await postWorkspaceInfo(
              workspaceId,
              workspaceTeamId,
              userId,
              user_id,
              createFeedResponse?.data?.id,
              // semrushProjectId,
              // samrushDomainName
            );
            await postUserData(
              userId,
              workspaceId,
              workspaceTeamId,
              user_id,
              newCustomerId,
              // stripeCustomerId
            );
            // await createFolder(userId, workspaceId);
            await userBillingDetail(workspaceId, userBillingDetailId);
            await postWorkspaceTeamInfo(
              workspaceTeamId,
              workspaceId,
              userId,
              user_id
            );
            dispatch(
              showToast({
                message: "User has been created successfully",
                type: "success",
              })
            );
            dispatch(setLoading(false));

          } catch (error) {
            dispatch(setLoading(false));
            dispatch(showToast({ message: error.message, type: "error" }));
          }
        }

        async function handleAutoSignIn() {
          try {
            dispatch(setLoading(true));
            const signInOutput = await autoSignIn();
            if (signInOutput.isSignedIn) {
              await handleFetchUserAttributes();
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(showToast({ message: error.message, type: "error" }));
          }
        }

        await handleAutoSignIn();
        // await runGTMetrixTest(workSpaceUrl, apiUsername, apiKey);

        setUnlockedSteps(3);
        setCurrentStep(3);
      }

    } catch (error) {
      dispatch(
        showToast({
          type: 'error',
          message: error.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const postCartItems = async (userId, workspaceId) => {
    try {
      dispatch(setLoading(true));
      if (cartItems?.length > 0) {
        for (const productItem of cartItems) {
          let cartId = uuid();
          const detail = {
            id: cartId,
            product_id: productItem.id,
            user_id: userId,
            workspace_id: workspaceId,
            image: productItem.image,
            name: productItem.name,
            period: productItem.period,
            price: productItem.price,
            type: productItem.type,
            plan: productItem.plan,
            quantity: productItem.quantity,
            sku: productItem.sku,
          };
          const saveItemInDB = await client.graphql({
            query: createCartItems,
            variables: { input: detail },
          });
        }
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  const postSubscriberID = async (
    workspaceId,
    subscriberId,
    newCustomerId,
    // stripeCustomerId
  ) => {
    try {
      const { email } = data
      dispatch(setLoading(true));

      const variable = {
        id: subscriberId,
        workspace_id: workspaceId,
        email: email,
        subscription_id: newCustomerId,
        // stripe_customer_id: stripeCustomerId,
      };

      const createSubscriber = await client.graphql({
        query: createSubscriberCard,
        variables: { input: variable },
      });

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  const userBillingDetail = async (workspaceId, userBillingDetailId) => {
    const { email, firstName, lastName, password, confirmPassword } =
      data;
    try {
      dispatch(setLoading(true));
      const variable = {
        id: userBillingDetailId,
        workspace_id:
          workspaceId,
        address: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        cvc: "",
        first_name: firstName,
        last_name: lastName,
        email: email,
        city: "",
        country: "",
        postal_code: "",
        state: "",
      };

      const updateUserBillingData = await client.graphql({
        query: createUserBilling,
        variables: { input: variable },
      });

      const result = updateUserBillingData.data.createUserBilling;
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  // const removeInvitation = async () => {
  //   dispatch(setLoading(true));
  //   const updatedResult = await client.graphql({
  //     query: updateTeamInvitation,
  //     variables: {
  //       input: {
  //         id: detail?.[0]?.id,
  //         status: false,
  //       },
  //     },
  //   });
  //   dispatch(setLoading(false));
  // };

  const postUserData = async (
    userId,
    workspaceId,
    workspaceTeamId,
    user_id,
    newCustomerId,
    // stripeCustomerId
  ) => {
    const { email, firstName, lastName, password, confirmPassword, workspaceName } =
      data;
    dispatch(setLoading(true));
    // getting date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const userDetail = {
      id: user_id,
      user_id: userId,
      user_name_first: firstName,
      user_name_second: lastName,
      display_picture:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTet-jk67T6SYdHW04eIMLygHzEeJKobi9zdg&usqp=CAU",
      email: email,
      joined_at: formattedDate,
      role: "workspace_admin",
      workspace: workspaceName,
      workspaceteamID:
        workspaceTeamId,
      workspace_id:
        workspaceId,
      subscription_id: newCustomerId,
      // stripe_customer_id: stripeCustomerId,
    };

    try {
      const result = await client.graphql({
        query: createUserInfo,
        variables: { input: userDetail },
      });
      setData(result.data.createUserInfo);

      // if (detail?.length > 0) {
      //   const variables = {
      //     filter: {
      //       id: {
      //         eq: result.data.createUserInfo.workspace_id,
      //       },
      //     },
      //   };

      //   const getUserData = await client.graphql({
      //     query: listWorkspaceInfos,
      //     variables: variables,
      //   });

      //   const userIdis =
      //     getUserData?.data?.listWorkspaceInfos?.items[0].user_id;
      //   const updatedUserIds = [...userIdis, userId];
      //   const updatedResult = await client.graphql({
      //     query: updateWorkspaceInfo,
      //     variables: {
      //       input: {
      //         id: result.data.createUserInfo.workspace_id,
      //         user_id: updatedUserIds,
      //         workspaceteamID:
      //           workspaceTeamId,
      //       },
      //     },
      //   });
      //   localStorage.setItem(
      //     "selectedWorkspaceId",
      //     workspaceId
      //   );
      // }

      // if (detail?.length > 0) {
      //   const variables = {
      //     filter: {
      //       id: {
      //         eq: result.data.createUserInfo.workspaceteamID,
      //       },
      //     },
      //   };

      //   const getUserData = await client.graphql({
      //     query: listWorkspaceTeams,
      //     variables: variables,
      //   });

      //   const userIdis =
      //     getUserData?.data?.listWorkspaceTeams?.items[0].user_id;

      //   const updatedUserIds = [...userIdis, userId];
      //   const updatedResult = await client.graphql({
      //     query: updateWorkspaceTeam,
      //     variables: {
      //       input: {
      //         id: result.data.createUserInfo.workspaceteamID,
      //         user_id: updatedUserIds,
      //       },
      //     },
      //   });

      //   if (updatedResult) {
      //     // removeInvitation();
      //   }
      // }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const postWorkspaceInfo = async (
    workspaceId,
    workspaceTeamId,
    userId,
    user_id,
    feedId,
    semrushProjectId,
    samrushDomainName
  ) => {
    dispatch(setLoading(true));
    try {
      const { workspaceName } = data
      // getting date
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      //getting url
      const workSpaceLowercase =
        workspaceName.toLowerCase();
      const workSpaceUrl = workSpaceLowercase.replace(/\s+/g, "-");
      const WorkSpaceUrl = `${workSpaceUrl}.rothbright.app`;
      const workspaceDetail = {
        id: workspaceId,
        name: workspaceName,
        url: WorkSpaceUrl,
        logo: "https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg",
        created_at: formattedDate,
        workspaceteamID: workspaceTeamId,
        user_id: [userId],
        feed_id: feedId,
        //   semrushProjectId: semrushProjectId,
        //   samrushDomainName: samrushDomainName
      };

      const result = await client.graphql({
        query: createWorkspaceInfo,
        variables: { input: workspaceDetail },
      });
      console.log("The result is:::", result)
      // setWorkspaceInfo(result.data.createWorkspaceInfo);
      localStorage.setItem(
        "selectedWorkspaceId",
        workspaceId
      );

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const postWorkspaceTeamInfo = async (
    workspaceTeamId,
    workspaceId,
    userId,
    user_id
  ) => {
    dispatch(setLoading(true));

    try {

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const workspaceTeamDetail = {
        id:
          workspaceTeamId,
        workspace_id:
          [workspaceId],
        user_id: [userId],
        joined_at: formattedDate,
        status: true,
        workspaceinfoID:
          workspaceId,
      };

      const result = await client.graphql({
        query: createWorkspaceTeam,
        variables: { input: workspaceTeamDetail },
      });

      // setWorkspaceTeamInfo(result.data.createWorkspaceTeam);


      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  // async function handleSignUpConfirmation({ username, confirmationCode }) {

  //   const e = arguments[0];
  //   e.preventDefault();
  //   const createNewCustomer = {
  //     createCustomerProfileRequest: {
  //       merchantAuthentication: {
  //         name: merchant_name,
  //         transactionKey: transaction_key,
  //       },
  //       profile: {
  //         email: email,
  //       },
  //     },
  //   };
  //   let userId;
  //   let user_id;
  //   let workspaceId;
  //   let workspaceTeamId;
  //   let userBillingDetailId;
  //   let subscriberId;
  //   try {
  //     dispatch(setLoading(true));
  //     if (combinedCode.length < 6) {
  //       dispatch(setLoading(false));
  //       dispatch(
  //         showToast({
  //           message: "Please enter a valid 6-digit code",
  //           type: "error",
  //         })
  //       );
  //       return;
  //     }

  //     const createAuthorizeCustomer = async () => {
  //       const response = await axios.post(authorize_api, createNewCustomer);
  //       if (
  //         response?.data?.messages?.resultCode === "Error" &&
  //         response?.data?.messages?.message?.[0]?.code === "E00039"
  //       ) {
  //         const getCustomer = {
  //           getCustomerProfileRequest: {
  //             merchantAuthentication: {
  //               name: merchant_name,
  //               transactionKey: transaction_key,
  //             },
  //             email,
  //           },
  //         };
  //         const fetchCustomerData = await axios.post(
  //           authorize_api,
  //           getCustomer
  //         );
  //         const id = fetchCustomerData?.data?.profile?.customerProfileId;
  //         return id;
  //       } else {
  //         const id = response.data.customerProfileId;
  //         return id;
  //       }
  //     };

  //     const newCustomerId = await createAuthorizeCustomer();
  //     const stripeCustomerId = await createStripeCustomer(email);
  //     const { isSignUpComplete, nextStep } = await confirmSignUp({
  //       username: email,
  //       confirmationCode: combinedCode,
  //     });

  //     if (isSignUpComplete) {
  //       async function handleFetchUserAttributes() {
  //         try {
  //           dispatch(setLoading(true));
  //           const userAttributes = await fetchUserAttributes();
  //           const userObjectString = JSON.stringify(userAttributes);
  //           localStorage.setItem(
  //             "user",
  //             JSON.stringify({
  //               ...userAttributes,
  //               role:
  //                 detail?.length > 0 ? detail?.[0]?.role : "workspace_admin",
  //             })
  //           );
  //           dispatch(setUserInfo({ status: userObjectString }));

  //           workspaceId = uuid();
  //           user_id = uuid();
  //           userId = userAttributes?.sub;
  //           workspaceTeamId = uuid();
  //           userBillingDetailId = uuid();
  //           subscriberId = uuid();
  //           // Desktend API integration

  //           // Login to Desktend
  //           let createFeedResponse;
  //           if (!detail?.length > 0) {
  //             const loginResponse = await axios.post(desktendLoginUrl, {
  //               username: desktendEmail,
  //               password: desktendPassword // You'll need to determine how to securely handle the password
  //             });
  //             console.log("The login response of desktent api is:::", loginResponse)
  //             if (loginResponse.data.accessToken) {
  //               // Create empty feed in Desktend
  //               createFeedResponse = await axios.post(desktendFeedsUrl, {
  //                 name: workSpace,
  //                 mode: "Regular"
  //                 // Add any other required fields for creating a feed
  //               }, {
  //                 headers: {
  //                   'Authorization': `Bearer ${loginResponse.data.accessToken}`,
  //                   'Desk': desktendDesk
  //                 }
  //               });

  //               if (createFeedResponse.data) {
  //                 console.log('Empty feed created successfully in Desktend');
  //               }
  //             }
  //           }

  //           await postCartItems(userId, workspaceId);
  //           await postSubscriberID(
  //             workspaceId,
  //             subscriberId,
  //             newCustomerId,
  //             stripeCustomerId
  //           );
  //           await postWorkspaceInfo(
  //             workspaceId,
  //             workspaceTeamId,
  //             userId,
  //             user_id,
  //             createFeedResponse?.data?.id,
  //             semrushProjectId,
  //             samrushDomainName
  //           );
  //           await postUserData(
  //             userId,
  //             workspaceId,
  //             workspaceTeamId,
  //             user_id,
  //             newCustomerId,
  //             stripeCustomerId
  //           );
  //           // await createFolder(userId, workspaceId);
  //           await userBillingDetail(workspaceId, userBillingDetailId);
  //           await postWorkspaceTeamInfo(
  //             workspaceTeamId,
  //             workspaceId,
  //             userId,
  //             user_id
  //           );
  //           dispatch(
  //             showToast({
  //               message: "User has been created successfully",
  //               type: "success",
  //             })
  //           );
  //           dispatch(setLoading(false));

  //         } catch (error) {
  //           dispatch(setLoading(false));
  //           dispatch(showToast({ message: error.message, type: "error" }));
  //         }
  //       }

  //       async function handleAutoSignIn() {
  //         try {
  //           dispatch(setLoading(true));
  //           const signInOutput = await autoSignIn();
  //           if (signInOutput.isSignedIn) {
  //             await handleFetchUserAttributes();
  //           }
  //           dispatch(setLoading(false));
  //         } catch (error) {
  //           dispatch(setLoading(false));
  //           dispatch(showToast({ message: error.message, type: "error" }));
  //         }
  //       }

  //       await handleAutoSignIn();
  //       // await runGTMetrixTest(workSpaceUrl, apiUsername, apiKey);
  //       if (cartItems?.length > 0) {
  //         navigate("/cart");
  //       } else {
  //         navigate("/home");
  //       }
  //     }

  //     dispatch(setLoading(false));
  //   } catch (error) {
  //     dispatch(setLoading(false));
  //     dispatch(showToast({ message: error.message, type: "error" }));
  //   }
  // }

  const handleCodeChange = (index, value) => {
    const sanitizedValue = value.slice(0, 1);
    const newCodes = [...code];

    newCodes[index] = sanitizedValue;

    if (sanitizedValue === '' && index > 0) {
      document.getElementById(`code${index - 1}`).focus();
    } else if (index < 5 && sanitizedValue !== '') {
      document.getElementById(`code${index + 1}`).focus();
    }

    setCode(newCodes);
  };

  const handlePaste = (e, index) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text');
    const newCodes = [...code];
    let pasteIndex = index;

    for (let i = 0; i < pastedText.length && pasteIndex < 6; i++) {
      newCodes[pasteIndex++] = pastedText[i];
    }

    setCode(newCodes);

    if (pasteIndex < 6) {
      document.getElementById(`code${pasteIndex}`).focus();
    }
  };

  const resendVerificationCode = async () => {
    try {
      dispatch(setLoading(true));

      await resendSignUpCode({
        username: data.email,
      });
    } catch (error) {
      dispatch(
        showToast({
          type: 'error',
          message: error.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getReport = async (e) => {
    e.preventDefault();
    const selected = localStorage.getItem("selectedWorkspaceId")

    try {
      dispatch(setLoading(true));

      const formData = new FormData(e.target);

      const rawValue = formData.get('domain');
      const domain = rawValue.replace(/^(https?:\/\/)?(www\.)?/, 'https://');
      console.log("The domain is::", domain)
      localStorage.setItem('websiteUrl', domain);
      const veriable = {
        id: selected,
        samrushDomainName: domain
      }
      const updatedResult = await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selected,
            samrushDomainName: domain,
          },
        },
      });
      if (updatedResult) {

        navigate('/home');
      }
    } catch (error) {
      dispatch(
        showToast({
          type: 'error',
          message: error.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (!stepRefs.current[currentStep]) return;

    setStepSelectorOffset(
      stepRefs.current[currentStep].offsetLeft +
      stepRefs.current[currentStep].offsetWidth / 2
    );
  }, [currentStep]);

  useEffect(() => {
    const newCombinedCode = code.filter(Boolean).join('');
    setCombinedCode(newCombinedCode);
  }, [code]);

  return (
    <Container
      maxWidth={false}
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        backgroundColor: '#F5F5F5',
      }}
    >
      <Box
        component='img'
        src={Logo}
        alt='logo'
        sx={{
          width: 220,
          height: 'auto',
        }}
      />
      <Box
        sx={{
          px: {
            xs: 2,
            sm: 7.5,
          },
          py: 2,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: {
            xs: 2,
            sm: 7.5,
          },
          borderRadius: 1.5,
          background:
            'linear-gradient(90deg, #F4260C 0%, #17CCF9 100%)',
        }}
      >
        <Box
          sx={{
            width: 10,
            position: 'absolute',
            bottom: -4,
            left: stepSelectorOffset,
            color: '#FFFFFF',
          }}
        >
          <svg viewBox='0 0 12 10' fill='none'>
            <path d='M0 10L6 0L12 10H0Z' fill='currentColor' />
          </svg>
        </Box>
        <Button
          ref={(el) => (stepRefs.current[0] = el)}
          onClick={() => handleCurrentStepChange(0)}
          sx={{
            minWidth: 0,
            color: currentStep === 0 ? '#FFFFFF' : '#FFFFFFB2',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: 1.25,
            textTransform: 'none',
          }}
        >
          01- Workspace
        </Button>
        <Button
          ref={(el) => (stepRefs.current[1] = el)}
          disabled={unlockedSteps < 1}
          onClick={() => handleCurrentStepChange(1)}
          sx={{
            minWidth: 0,
            color: currentStep === 1 ? '#FFFFFF' : '#FFFFFFB2',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: 1.25,
            textTransform: 'none',
            '&.Mui-disabled': {
              color: '#FFFFFFB2',
            },
          }}
        >
          02- Create Account
        </Button>
        <Button
          ref={(el) => (stepRefs.current[2] = el)}
          disabled={unlockedSteps < 2}
          onClick={() => handleCurrentStepChange(2)}
          sx={{
            minWidth: 0,
            color: currentStep === 2 ? '#FFFFFF' : '#FFFFFFB2',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: 1.25,
            textTransform: 'none',
            '&.Mui-disabled': {
              color: '#FFFFFFB2',
            },
          }}
        >
          03- Verify
        </Button>
        <Button
          ref={(el) => (stepRefs.current[3] = el)}
          disabled={unlockedSteps < 3}
          onClick={() => handleCurrentStepChange(3)}
          sx={{
            minWidth: 0,
            color: currentStep === 3 ? '#FFFFFF' : '#FFFFFFB2',
            fontWeight: 700,
            fontSize: 12,
            lineHeight: 1.25,
            textTransform: 'none',
            '&.Mui-disabled': {
              color: '#FFFFFFB2',
            },
          }}
        >
          04- Website
        </Button>
      </Box>
      {currentStep === 0 ? (
        <Box
          component='form'
          onSubmit={handleSetWorkspace}
          sx={{
            width: {
              xs: '60%',
              lg: 740,
            },
            p: 5,
            display: {
              xs: 'flex',
              md: 'grid',
            },
            flexDirection: 'column',
            gridAutoRows: 'min-content',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2.5,
            borderRadius: 3,
            border: '1px solid #EEEEEE',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              pb: 1.25,
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2.5,
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Typography
              sx={{
                color: '#000000',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Enter Workspace Name
            </Typography>
          </Box>
          <Typography
            sx={{
              gridColumn: 'span 2',
              color: '#666666',
              fontWeight: 400,
              fontSize: 16,
              lineHeight: 1.25,
            }}
          >
            Rothbright portal provides your Workplace with a central
            place for you to manage all of your digital services.
          </Typography>
          <TextField
            label='Workspace Name'
            id='workspace-name'
            name='workspace-name'
            type='text'
            value={data.workspaceName}
            onChange={(e) =>
              setData({ ...data, workspaceName: e.target.value })
            }
            error={errors.workspaceName ? true : false}
            helperText={
              errors.workspaceName ? errors.workspaceName : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FontAwesomeIcon icon={faLayerGroup} />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              maxWidth: 460,
              mx: 'auto',
              my: 2.5,
              gridColumn: 'span 2',
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <Box
            sx={{
              gridColumn: 'span 2',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: '#999999',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.25,
                textAlign: 'center',
              }}
            >
              Already have an account?{' '}
              <Link
                href='/login'
                sx={{
                  color: '#187DF0',
                  fontWeight: 700,
                }}
              >
                Sign In
              </Link>
            </Typography>
            <Button
              type='submit'
              sx={{
                py: 2,
                px: 6.625,
                color: '#FFFFFF',
                fontWeight: 700,
                fontSize: 12,
                lineHeight: 1.25,
                textTransform: 'initial',
                border: '1px solid #EEEEEE',
                borderRadius: 1.5,
                backgroundColor: '#0CA350',
                alignItems: 'center', justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#0CA350',
                },
              }}
            >
              CREATE <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} />
            </Button>
          </Box>
        </Box>
      ) : currentStep === 1 ? (
        <Box
          component='form'
          onSubmit={handleCreateAccount}
          sx={{
            width: {
              xs: '60%',
              lg: 740,
            },
            p: 5,
            display: {
              xs: 'flex',
              md: 'grid',
            },
            flexDirection: 'column',
            gridAutoRows: 'min-content',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2.5,
            borderRadius: 3,
            border: '1px solid #EEEEEE',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              pb: 1.25,
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2.5,
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Typography
              sx={{
                color: '#000000',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Enter Account Details
            </Typography>
          </Box>
          <TextField
            label='Email'
            id='email'
            name='email'
            type='email'
            value={data.email}
            onChange={(e) =>
              setData({ ...data, email: e.target.value })
            }
            error={errors.email ? true : false}
            helperText={errors.email ? errors.email : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </InputAdornment>
              ),
            }}
            sx={{
              gridColumn: 'span 2',
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <TextField
            label='First Name'
            id='first-name'
            name='first-name'
            type='text'
            value={data.firstName}
            onChange={(e) =>
              setData({ ...data, firstName: e.target.value })
            }
            error={errors.firstName ? true : false}
            helperText={errors.firstName ? errors.firstName : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FontAwesomeIcon icon={faUser} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <TextField
            label='Last Name'
            id='last-name'
            name='last-name'
            type='text'
            value={data.lastName}
            onChange={(e) =>
              setData({ ...data, lastName: e.target.value })
            }
            error={errors.lastName ? true : false}
            helperText={errors.lastName ? errors.lastName : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <FontAwesomeIcon icon={faUser} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <TextField
            label='Password'
            id='password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            value={data.password}
            onChange={(e) =>
              setData({ ...data, password: e.target.value })
            }
            error={errors.password ? true : false}
            helperText={errors.password ? errors.password : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    onClick={handleTogglePasswordVisibility}
                    sx={{
                      minWidth: 0,
                      m: 0,
                      p: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        showPassword
                          ? faEyeSlash
                          : faEye
                      }
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <TextField
            label='Confirm Password'
            id='confirm-password'
            name='confirm-password'
            type={showConfirmPassword ? 'text' : 'password'}
            value={data.confirmPassword}
            onChange={(e) =>
              setData({
                ...data,
                confirmPassword: e.target.value,
              })
            }
            error={errors.confirmPassword ? true : false}
            helperText={
              errors.confirmPassword
                ? errors.confirmPassword
                : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button
                    onClick={
                      handleToggleConfirmPasswordVisibility
                    }
                    sx={{
                      minWidth: 0,
                      m: 0,
                      p: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={
                        showConfirmPassword
                          ? faEyeSlash
                          : faEye
                      }
                    />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 1.5,
              border: '1px solid #EEEEEE',
              background: 'rgba(23, 204, 249, 0.05)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid transparent',
                transition: 'border-color 0.1s ease-in-out',
              },
              '& svg': {
                width: 16,
                aspectRatio: 1,
                color: 'hsla(0, 0%, 60%, 1)',
              },
            }}
          />
          <Box
            sx={{
              gridColumn: 'span 2',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              type='submit'
              sx={{
                py: 2,
                px: 6.625,
                color: '#FFFFFF',
                fontWeight: 700,
                fontSize: 12,
                lineHeight: 1.25,
                textTransform: 'initial',
                border: '1px solid #EEEEEE',
                borderRadius: 1.5,
                backgroundColor: '#0CA350',
                alignItems: 'center', justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#0CA350',
                },
              }}
            >
              CONTINUE <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} />
            </Button>
          </Box>
        </Box>
      ) : currentStep === 2 ? (
        <Box
          component='form'
          onSubmit={handleVerification}
          sx={{
            width: {
              xs: '60%',
              lg: 740,
            },
            p: 5,
            display: 'grid',
            gridAutoRows: 'min-content',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2.5,
            borderRadius: 3,
            border: '1px solid #EEEEEE',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              pb: 1.25,
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2.5,
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Typography
              sx={{
                color: '#000000',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Enter Verification Code
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 2',
            }}
          >
            <Typography
              sx={{
                color: '#666666',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.25,
              }}
            >
              We’ve sent a 6 digit code to{' '}
              <Typography
                component='span'
                sx={{
                  color: '#187DF0',
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: 1.25,
                  textDecorationLine: 'underline',
                }}
              >
                {data.email}
              </Typography>
              . Code expires shortly. Please Enter soon.
            </Typography>
            <Box
              sx={{
                mt: 5,
                mb: 7.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: {
                  xs: 1,
                  md: 2.5,
                },
              }}
            >
              {code.map((_, idx) => (
                <TextField
                  key={idx}
                  id={`code${idx}`}
                  type='text'
                  value={code[idx]}
                  onChange={(e) =>
                    handleCodeChange(idx, e.target.value)
                  }
                  onPaste={(e) => handlePaste(e, idx)}
                  sx={{
                    minWidth: {
                      xs: 32,
                      md: 48,
                    },
                    width: { xs: 32, md: 48 },
                    height: 48,
                    borderRadius: 1.5,
                    border: '1px solid #EEEEEE',
                    background: 'rgba(23, 204, 249, 0.05)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid transparent',
                      transition:
                        'border-color 0.1s ease-in-out',
                    },
                    '& svg': {
                      width: 16,
                      aspectRatio: 1,
                      color: 'hsla(0, 0%, 60%, 1)',
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 2',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: '#999999',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.25,
                textAlign: 'center',
              }}
            >
              <Button
                type='button'
                onClick={resendVerificationCode}
                sx={{
                  color: '#187DF0',
                  fontWeight: 700,
                  textDecorationLine: 'underline',
                  '&:hover': {
                    textDecorationLine: 'underline',
                  },
                }}
              >
                RE-SEND CODE?
              </Button>
            </Typography>
            <Button
              type='submit'
              sx={{
                py: 2,
                px: 6.625,
                color: '#FFFFFF',
                fontWeight: 700,
                fontSize: 12,
                lineHeight: 1.25,
                textTransform: 'initial',
                border: '1px solid #EEEEEE',
                borderRadius: 1.5,
                backgroundColor: '#0CA350',
                '&:hover': {
                  backgroundColor: '#0CA350',
                },
              }}
            >
              VERIFY <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          component='form'
          onSubmit={getReport}
          sx={{
            width: {
              xs: '60%',
              lg: 740,
            },
            p: 5,
            display: 'grid',
            gridAutoRows: 'min-content',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2.5,
            borderRadius: 3,
            border: '1px solid #EEEEEE',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              pb: 1.25,
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2.5,
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Typography
              sx={{
                color: '#000000',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Enter Your Website Domain Name
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 2',
            }}
          >
            <Typography
              sx={{
                color: '#666666',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.25,
              }}
            >
              To get your digital health check, please confirm or
              update website address below.
            </Typography>
            <Box
              sx={{
                mt: 5,
                mb: 7.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2.5,
              }}
            >
              <TextField
                id='domain'
                name='domain'
                type='text'
                placeholder='www.yourdomain.com'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <FontAwesomeIcon icon={faGlobe} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: 460,
                  borderRadius: 1.5,
                  border: '1px solid #EEEEEE',
                  background: 'rgba(23, 204, 249, 0.05)',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid transparent',
                    transition:
                      'border-color 0.1s ease-in-out',
                  },
                  '& svg': {
                    width: 16,
                    aspectRatio: 1,
                    color: 'hsla(0, 0%, 60%, 1)',
                  },
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 2',
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              type='submit'
              sx={{
                py: 2,
                px: 6.625,
                color: '#FFFFFF',
                fontWeight: 700,
                fontSize: 12,
                lineHeight: 1.25,
                textTransform: 'initial',
                border: '1px solid #EEEEEE',
                borderRadius: 1.5,
                backgroundColor: '#0CA350',
                '&:hover': {
                  backgroundColor: '#0CA350',
                },
              }}
            >
              GET REPORT <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faArrowRight} />
            </Button>
          </Box>
        </Box>
      )}
      <Typography
        sx={{
          color: '#999999',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: 1.25,
          textAlign: 'center',
        }}
      >
        By creating an account you agree to our{' '}
        <Link
          href='https://rothbright.com/terms-and-conditions/'
          target='__blank'
          sx={{
            color: '#187DF0',
            fontWeight: 700,
          }}
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          href='https://rothbright.com/privacy-policy/'
          target='__blank'
          sx={{
            color: '#187DF0',
            fontWeight: 700,
          }}
        >
          Privacy Policy
        </Link>
      </Typography>
    </Container>
  );
}